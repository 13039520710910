import {LocalStorageKeyName} from './local-storage.class';

export class LocalStorageService {
    public storageAvailable: boolean;

    constructor() {
        this.storageAvailable = LocalStorageService._storageAvailable();
    }

    /**
     * Returns item from localStorage
     * @param key - Key to retrieve
     */
    public getItem(key: LocalStorageKeyName): string {
        if (this.storageAvailable) {
            if (!LocalStorageService._isExpired(key)) {
                return localStorage.getItem(key);
            } else {
                this.removeItem(key);
            }
        }
        return null;
    }

    /**
     * Removes item from localStorage
     * @param key - Key to remove
     */
    public removeItem(key: LocalStorageKeyName) {
        if (this.storageAvailable) {
            localStorage.removeItem(key);
            localStorage.removeItem(`${key}-expires`);
        }
    }

    /**
     * Sets item in localStorage
     * @param key - Key to associate value
     * @param value - Value for key
     * @param lifeInMinutes - Minutes before data expires
     */
    public setItem(key: LocalStorageKeyName, value: string, lifeInMinutes?: number) {
        if (this.storageAvailable) {
            localStorage.setItem(key, value);
            if (lifeInMinutes) {
                LocalStorageService._setExpiration(key, lifeInMinutes);
            }
        }
    }

    /**
     * Determines if the provided key is expired
     * @param key - Key to check
     * @private
     */
    private static _isExpired(key: LocalStorageKeyName): boolean {
        const expirationDateInMsString = localStorage.getItem(`${key}-expires`);
        if (expirationDateInMsString) {
            const expirationDateInMs = parseInt(expirationDateInMsString);
            const timeNowInMs = new Date().getTime();
            return timeNowInMs > expirationDateInMs;
        } else {
            return false;
        }
    }

    /**
     * Sets expiration on provided key
     * @param key - Key to expire
     * @param lifeInMinutes - Life in minutes of key
     * @private
     */
    private static _setExpiration(key: LocalStorageKeyName, lifeInMinutes: number) {
        const lifeInMs = lifeInMinutes * 60 * 1000;
        const expirationDateInMs = new Date().getTime() + lifeInMs;
        localStorage.setItem(`${key}-expires`, expirationDateInMs.toString());
    }

    /**
     * Returns true if client supports use of `localStorage`
     * @private
     */
    private static _storageAvailable(): boolean {
        try {
            const storage = window.localStorage;
            const x = `__storage_test__`;
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return false;
        }
    }
}
